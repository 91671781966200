import Vue from 'vue'
import Router from 'vue-router'
// import stldemo from "@/components/StlDemo"
// import objdemo from "@/components/ObjDemo"
// import MtlDemo from "@/components/MtlDemo"

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

// 安装路由
Vue.use(Router)


export default new Router({
	routes: [{
			path: '/',
			name: 'Demo',
			component: resolve => (require(["@/components/Demo"], resolve)),
			children: [{
				path: 'stl',
				name: 'stldemo',
				// component: stldemo
				component: resolve => (require(["@/components/StlDemo"], resolve)),
				meta: {
					title: '壹盒动漫',

				}
			}, {
				path: 'obj',
				name: 'objdemo',
				component: resolve => (require(["@/components/ObjDemo"], resolve)),
				meta: {
					title: '壹盒动漫',

				}
			}, {
				path: 'mtl',
				name: 'mtlDemo',
				// component: MtlDemo
				component: resolve => (require(["@/components/MtlDemo"], resolve)),
				meta: {
					title: '壹盒动漫',

				}
			}, ]
		},
		{
			path: '/policy',
			name: 'policy',
			// component: MtlDemo
			component: resolve => (require(["@/components/policy"], resolve)),
			meta: {
				title: '壹盒动漫隐私政策',
				noCache: true,
				keepAlive: true //需要缓存

			}
		},
		{
			path: '/user',
			name: 'user',
			// component: MtlDemo
			component: resolve => (require(["@/components/User"], resolve)),
			meta: {
				title: '壹盒动漫用户注册和服务协议',
				noCache: true,
				keepAlive: true //需要缓存
			}
		},
		{
			path: '/auction',
			name: 'auction',
			// component: MtlDemo
			component: resolve => (require(["@/components/ASA"], resolve)),
			meta: {
				title: '壹盒动漫竞拍服务协议',
				noCache: true,
				keepAlive: true //需要缓存
			}
		},
		{
			path: '/purchase',
			name: 'purchase',
			// component: MtlDemo
			component: resolve => (require(["@/components/purchase"], resolve)),
			meta: {
				title: '购买须知',
				noCache: true,
				keepAlive: true //需要缓存
			}
		},

		// {
		// 	path: '/asa',
		// 	name: 'asa',
		// 	// component: MtlDemo
		// 	component: resolve => (require(["@/components/ASA"], resolve)),
		// 	meta: {
		// 		title: '壹盒动漫竞拍服务协议',
		// 		noCache: true,
		// 		keepAlive: true //需要缓存
		// 	}
		// }, 
		{
			path: '/SDK',
			name: 'SDK',
			// component: MtlDemo
			component: resolve => (require(["@/components/SDK"], resolve)),
			meta: {
				title: '壹盒动漫第三方SDK明示',
				noCache: true,
				keepAlive: true //需要缓存
			}
		},
		{
			path: '/tuiguang',
			name: 'tuiguang',
			// component: MtlDemo
			component: resolve => (require(["@/components/tuiguang"], resolve)),
			meta: {
				title: '壹盒动漫',
				noCache: true,
				keepAlive: true //需要缓存
			}
		}
	]
})
