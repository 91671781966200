<template>
	<div id="app">
		<keep-alive>
			<router-view />
		</keep-alive>
	</div>
</template>

<script>
	export default {
		name: 'App',
		data() {
			return {
				handlechufa: ""
			}
		},
		//     watch:{


		//   $route: {
		//     handler: function(val, oldVal){
		//          console.log( val)
		//          console.log( this.handlechufa)
		//       this.handlechufa=val.path 
		//         console.log( this.handlechufa)     
		//     },
		//     // 深度观察监听
		//     deep: true
		//   }

		//     },
		created() {
			//   this.toLink('/');   
		},
		methods: {
			toLink(val) {
				this.$router.push(val);
			},

		}
	}
</script>

<style>
	html,
	body,
	#app {
		height: 99%;

	}

	.conter {
		margin: 40px 30px;
	}

	.title {
		widows: 171px;
		height: 28px;
		font-size: 20px;
		color: #0A1B33;
		font-weight: 800;
		display: flex;
		justify-content: space-between;
	}

	.title_two {
		font-size: 12px;
		color: #999999;

	}

	p {
		text-align: left;
		/* text-indent: 2em; */
		line-height: 20px;
		color: #0A1B33;
		font-size: 12px;
		/* margin-bottom: 15px; */
	}

	h1 {
		font-size: 14px;
		color: #0A1B33;
		font-weight: 600;
		margin-bottom: 10px;
	}

	h2 {
		font-size: 13px;
		color: #0A1B33;
		font-weight: 600;
		margin-bottom: 10px;
	}

	h3 {
		font-size: 12px;
		color: #0A1B33;
		font-weight: 550;
		margin-bottom: 10px;
	}
</style>
